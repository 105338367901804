import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import marked from "marked"
import { graphql } from "gatsby"

import ToggleBtn from "../components/toggle-btn"
import "../scss/modules/single-examen-component.scss"
import FloatingBtn from "../components/floating-btn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ExamenTemplate = ({ data }) => (
  <Layout>
    <div className={"single-exam-page"}>
      <Seo
        title={data.strapiExamens.titre}
        description={data.strapiExamens.Seo}
      />

      <div className={"wrapper-banner"}>
        {data.strapiExamens.image.length === 0 ? (
          <div></div>
        ) : (
          <div
            className="banner"
            style={{
              backgroundImage: `url(https://admin.radiologieclaudegalien.fr${data.strapiExamens.image[0].url})`,
            }}
          >
            <h1 className={"title-exam text-uppercase"}>{data.strapiExamens.titre}</h1>
            {data.strapiExamens.sousexamen.length>0 &&
              <ToggleBtn currentExam={data.strapiExamens} title={false} />
            }
            {data.strapiExamens.entete ? (
              <div
                className="header-content"
                dangerouslySetInnerHTML={{
                  __html: marked(data.strapiExamens.entete),
                }}
              ></div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div className={"single-wrapper-exam"}>
        <div
          className={"item-description"}
          dangerouslySetInnerHTML={{
            __html: marked(data.strapiExamens.description),
          }}
        ></div>
        {data.strapiExamens.doclink &&
          <div className={"quest-container"}>
            <a href={data.strapiExamens.doclink} target={"_blank"}>
              <FontAwesomeIcon icon="fa-solid fa-download" className={"icon-download"} />
              <span>Télécharger le questionnaire à remettre lors du rendez-vous</span></a>
          </div>
        }
        <div className={"circles"}>
          <span className={"circlesOne"}></span>
          <span className={"circlesTwo"}></span>
          <span className={"circlesThird"}></span>
          <span className={"circlesFourth"}></span>
          <span className={"circlesFifth"}></span>
        </div>
      </div>
      <FloatingBtn/>
    </div>

  </Layout>
)

export default ExamenTemplate

export const query = graphql`
  query ExamenTemplate($id: String!) {
    strapiExamens(id: { eq: $id }) {
      id
      titre
      image {
        url
      }
      description
      sousexamen {
        id
        titre
      }
      SEO
      doclink
    }
  }
`
