import React from "react"
import Link from "gatsby-link"
import arrow from "../images/ic-arrow.png"
import "../scss/modules/toggle-btn-component.scss"

class toggleBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  toggle = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  filter = data => {
    return data.sort((a, b) => a.titre.localeCompare(b.titre))
  }

  // if first item need to be the title of the btn
  capitalize = title => {
    return title.charAt(0).toUpperCase() + title.slice(1)
  }

  // remove current item
  rmCurrent = (items, id) => {
    let cleanId = Number(id.replace("Sousexamen_", ""))
    return (
      <ul className={"list position-absolute"}>
        {items
          .filter(item => item.id !== cleanId)
          .map((x, index) => {
            return (
              <Link
                to={"/Examens/" + this.props.currentExam.titre + "/" + x.titre}
                key={index}
              >
                <li>{x.titre}</li>
              </Link>
            )
          })}
      </ul>
    )
  }

  render() {
    const { active } = this.state
    return (
      <div
        className={
          active
            ? "active toggle-btn-component relative"
            : "toggle-btn-component relative"
        }
      >
        <button onClick={() => this.toggle()}>
          {this.props.title ? (
            <span>{this.capitalize(this.props.currentSousExam.titre)}</span>
          ) : (
            <span>Choisissez un examen</span>
          )}
          <img src={arrow} alt="ic-arrow" />
        </button>
        {this.props.title ? (
          this.rmCurrent(
            this.filter(this.props.currentExam.sousexamen),
            this.props.currentSousExam.id
          )
        ) : (
          <ul className={"list"}>
            {this.filter(this.props.currentExam.sousexamen).map((x, index) => {
              return (
                <Link
                  to={
                    "/Examens/" + this.props.currentExam.titre + "/" + x.titre
                  }
                  key={index}
                >
                  <li>{x.titre}</li>
                </Link>
              )
            })}
          </ul>
        )}
      </div>
    )
  }
}

export default toggleBtn
